:root {
  --headerHeight: 140px;
  --generalBorderWidth: 4px;
  --contentOverflow: 130px;
  --mobileImageHeight: 460px;
  --altBackground: #F0F1F5;
}
:root .pageBackgroundLightblue {
  --altBackground: #fff;
}
@media (max-width: 1279px) {
  :root {
    --headerHeight: 100px;
  }
}
@media (max-width: 767px) {
  :root {
    --headerHeight: 80px;
    --mobileImageHeight: 260px;
  }
}
:root {
  --deskWidth: 1356px;
  --deskSpace: 60px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 1279px) {
  :root {
    --deskSpace: 40px;
  }
}
@media (max-width: 767px) {
  :root {
    --deskSpace: 24px;
  }
}
:root {
  --spacePart: 15px;
}
@media (max-width: 1279px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 55px;
}
@media (max-width: 1279px) {
  :root {
    --spaceUnit: 40px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 30px;
  }
}
:root {
  --spaceTotal: 70px;
}
@media (max-width: 1279px) {
  :root {
    --spaceTotal: 50px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 40px;
  }
}
:root {
  --js-breakpoint: breakpointLarge;
}
@media (max-width: 1279px) {
  :root {
    --js-breakpoint: breakpointMedium;
  }
}
@media (max-width: 767px) {
  :root {
    --js-breakpoint: breakpointSmall;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: transparent;
  font-family: inherit;
  font-size: 18px;
  line-height: 1.33333333;
  font-weight: 600;
  cursor: pointer;
  color: #000;
  padding: 0 0 2px 0;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  background-size: 100% var(--generalBorderWidth);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #004093, #004093);
  transition: all 0.2s;
}
.button:hover,
.button:focus,
.unit--isAnchor:hover .button {
  background-size: 0 var(--generalBorderWidth);
}
/* source-sans-3-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'SourceSans';
  font-style: normal;
  font-weight: 400;
  src: url('/extras/fonts/source-sans-3-v15-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* source-sans-3-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'SourceSans';
  font-style: normal;
  font-weight: 600;
  src: url('/extras/fonts/source-sans-3-v15-latin-600.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* source-sans-3-900 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'SourceSans';
  font-style: normal;
  font-weight: 900;
  src: url('/extras/fonts/source-sans-3-v15-latin-900.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
}
*::selection {
  background: #004093;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: 600;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 18px;
  font-family: 'SourceSans', sans-serif;
  line-height: 1.33333333;
}
.unit caption {
  display: none;
}
.flag {
  background: #004093;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.area .unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit__background {
  float: left;
  width: 100%;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.unit .part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.part__content {
  float: left;
  width: 100%;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
.lazyimage {
  visibility: hidden;
}
.lazyimage.lazyloaded {
  visibility: initial;
}
#edit .part video {
  pointer-events: none;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #DEE4ED;
}
.line hr.bold {
  border-bottom-width: var(--generalBorderWidth);
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.part.cb-youtube iframe,
.part.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.part.cb-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.part.cb-share .cb-service {
  float: left;
  margin-left: 16px;
}
.part.cb-share .cb-service:first-child {
  margin-left: 0;
}
.part.cb-share .cb-service * {
  float: left;
}
.part.cb-share .cb-service a {
  float: left;
  width: 50px;
  height: 50px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10000px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.part.cb-share .cb-service a:hover {
  filter: invert(1);
}
.part.cb-share .cb-service.cb-service-facebook-share a {
  background-size: auto 24px;
  background-image: url(/images/cb-default/socialShare-facebook-black.svg);
}
.part.cb-share .cb-service.cb-service-twitter a {
  background-size: auto 18px;
  background-image: url(/images/cb-default/socialShare-twitter-black.svg);
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '–';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 23.4375%;
  position: relative;
  overflow: hidden;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#slides img.lazyimage {
  visibility: initial;
  filter: blur(10px);
  transform: scale(1.05);
  transition: all 0.12s;
}
#slides img.lazyimage.lazyloaded {
  filter: blur(0);
  transform: scale(1);
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #004093;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #004093;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'SourceSans', sans-serif;
  line-height: 1.42857143;
  color: #000;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #004093;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #004093;
  font-size: 16px;
  font-weight: 600;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: transparent;
  font-family: inherit;
  font-size: 18px;
  line-height: 1.33333333;
  font-weight: 600;
  cursor: pointer;
  color: #000;
  padding: 0 0 2px 0;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  background-size: 100% var(--generalBorderWidth);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #004093, #004093);
  transition: all 0.2s;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus,
.unit--isAnchor:hover #disp .foot input,
.unit--isAnchor:hover #disp .foot a {
  background-size: 0 var(--generalBorderWidth);
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input[type*="radio"],
#disp input[type*="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1279px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'SourceSans', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 50px;
    padding-top: 13px;
    padding-bottom: 13px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  min-height: 50px;
  padding: 0 0 10px;
  appearance: none;
  border-bottom: var(--generalBorderWidth) solid #DEE4ED;
  position: relative;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form input.text:focus,
.unit.form textarea:focus {
  border-color: #004093;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 13px;
    padding-bottom: 13px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 24px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 13px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'SourceSans', sans-serif;
  font-size: 18px;
  line-height: 1.33333333;
  background: transparent;
  height: 50px;
  border: 1px solid #000;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  width: 100%;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop span::file-selector-button {
  background-color: transparent;
  font-family: inherit;
  font-size: 18px;
  line-height: 1.33333333;
  font-weight: 600;
  cursor: pointer;
  color: #000;
  padding: 0 0 2px 0;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  background-size: 100% var(--generalBorderWidth);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #004093, #004093);
  transition: all 0.2s;
  margin-right: 16px;
}
.unit.form .ship div.chop span::file-selector-button:hover,
.unit.form .ship div.chop span::file-selector-button:focus,
.unit--isAnchor:hover .unit.form .ship div.chop span::file-selector-button {
  background-size: 0 var(--generalBorderWidth);
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form .submit {
  float: right;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  background-color: transparent;
  font-family: inherit;
  font-size: 18px;
  line-height: 1.33333333;
  font-weight: 600;
  cursor: pointer;
  color: #000;
  padding: 0 0 2px 0;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  background-size: 100% var(--generalBorderWidth);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #004093, #004093);
  transition: all 0.2s;
}
.unit.form .submit:hover,
.unit.form .submit:focus,
.unit--isAnchor:hover .unit.form .submit {
  background-size: 0 var(--generalBorderWidth);
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #000;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: transparent;
  font-family: inherit;
  font-size: 18px;
  line-height: 1.33333333;
  font-weight: 600;
  cursor: pointer;
  color: #000;
  padding: 0 0 2px 0;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  background-size: 100% var(--generalBorderWidth);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #004093, #004093);
  transition: all 0.2s;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus,
.unit--isAnchor:hover .two-step-verification-container a {
  background-size: 0 var(--generalBorderWidth);
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.tableIcons {
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 24px;
}
@media (max-width: 767px) {
  .tableIcons {
    min-height: 14px;
  }
}
.tableIcon {
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 3px;
  background-color: #F86955;
}
.tableIcon:last-child {
  margin-right: 0 !important;
}
.tableIcon.tableIcon--Qblau {
  background-color: #004093;
}
.tableIcon.tableIcon--Qhellblau {
  background-color: #85D0EC;
}
.tableIcon.tableIcon--Qgruen {
  background-color: #94A791;
}
.tableIcon.tableIcon--Qweiss {
  background-color: transparent;
}
@media (max-width: 767px) {
  .tableIcon {
    width: 14px;
    height: 14px;
    margin-right: 3px;
  }
}
.table tr {
  border-top: var(--generalBorderWidth) solid #DEE4ED;
  border-bottom: var(--generalBorderWidth) solid #DEE4ED;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 18px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: 600;
}
.table td {
  padding: 18px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: 600;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.34680574vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
@media (max-width: 767px) {
  body.cb-toggle-target-active {
    overflow: hidden;
  }
}
iframe.srfVideo {
  max-width: 100% !important;
  max-height: 1000000px !important;
  height: auto !important;
  width: 100% !important;
  aspect-ratio: 1.77777778;
}
.logo {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;
}
.logo__image {
  width: auto;
  height: 46px;
}
@media (max-width: 767px) {
  .logo__image {
    height: 36px;
  }
}
.logo__claim {
  float: left;
  text-decoration: none;
  font-weight: 600;
  color: #004093;
  margin-top: 15px;
}
@media (max-width: 1279px) {
  .logo__claim {
    display: none;
  }
}
#head {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.section--three #head {
  margin-top: 70px;
  margin-bottom: calc((var(--spaceTotal) - 50px) * -1);
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'SourceSans', sans-serif;
  font-size: 18px;
  line-height: 1.33333333;
  color: #000;
  accent-color: #004093;
  overflow: hidden;
  hyphens: none;
  box-sizing: border-box;
  padding-top: var(--headerHeight);
}
.pageBackgroundLightblue .wrapper,
.layout4 .wrapper {
  background-color: #F0F1F5;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  background-color: #fff;
}
.layout4 .section--root {
  display: none;
}
.section--contact {
  background-color: #DEE4ED;
  margin-top: var(--spaceTotal);
}
.section--footer {
  background-color: #fff;
}
.layout5 .section--pfad {
  display: none;
}
.section--lightblue {
  background-color: #F0F1F5;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  padding-top: var(--spaceTotal);
  padding-bottom: var(--spaceTotal);
  display: none;
}
#edit.layout5 .section--lightblue.section--area7,
#view.layout5:not(.area7--empty) .section--lightblue.section--area7 {
  display: block;
}
.section__wrapper {
  float: left;
  width: 100%;
}
.section--header .section__wrapper {
  border-bottom: var(--generalBorderWidth) solid #DEE4ED;
}
.content {
  float: left;
  width: 100%;
}
.section--header .content {
  width: calc(100% + ((100vw - var(--deskWidth)) / 2));
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--headerHeight);
  box-sizing: border-box;
  padding-right: var(--deskSpace);
}
@media (max-width: 1476px) {
  .section--header .content {
    width: 100%;
    padding-right: 0;
  }
}
.section--root .content {
  margin-top: calc(70px - var(--spacePart));
  margin-bottom: var(--spaceUnit);
}
@media (max-width: 1279px) {
  .section--root .content {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .section--root .content {
    margin-top: calc(40px - var(--spacePart));
  }
}
.section--two .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.section--three .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.subcontent {
  float: left;
  width: 100%;
}
.layout3 .section--root .subcontent.subcontent--1,
.layout5 .section--root .subcontent.subcontent--1 {
  text-align: center;
}
.layout3 .section--root .subcontent.subcontent--2,
.layout5 .section--root .subcontent.subcontent--2 {
  display: none;
}
@media (max-width: 1279px) {
  .section--root .subcontent.subcontent--1 {
    order: 2;
  }
  .section--root .subcontent.subcontent--2 {
    order: 1;
  }
}
.headerlinks {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
#services,
#mobileServices {
  float: left;
  display: flex;
  align-items: center;
}
#services .meta,
#mobileServices .meta {
  float: left;
  font-size: 14px;
  line-height: 1.57142857;
  text-decoration: none;
  margin-right: 16px;
}
#services .meta:last-child,
#mobileServices .meta:last-child {
  margin-right: 0;
}
#services .meta.find,
#mobileServices .meta.find,
#services .meta.service_linkedin,
#mobileServices .meta.service_linkedin {
  width: 22px;
  height: 22px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-color: #000;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
}
#services .meta.find:hover,
#mobileServices .meta.find:hover,
#services .meta.service_linkedin:hover,
#mobileServices .meta.service_linkedin:hover {
  background-color: #004093;
}
#services .meta.find,
#mobileServices .meta.find {
  mask-image: url(/images/icon-search.svg);
}
#services .meta.service_linkedin,
#mobileServices .meta.service_linkedin {
  mask-image: url(/images/icon-linkedin.svg);
}
#services .meta.service_sharepoint,
#mobileServices .meta.service_sharepoint {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 32px;
  height: 32px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/sharepoint.svg);
}
#services {
  margin-bottom: 26px;
}
@media (max-width: 1279px) {
  #services {
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  #services {
    display: none;
  }
}
.pfad {
  float: left;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  font-size: 14px;
  line-height: 1.57142857;
  margin-top: 10px;
}
.pfad::-webkit-scrollbar {
  display: none;
}
.pfad a {
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;
  padding: 0 8px;
}
.pfad a:first-child {
  padding-left: 0;
}
.pfad a:last-child {
  padding-right: 0;
}
.pfad a.here {
  font-weight: 600;
}
.part--moodLogo {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.part--moodLogo .placeholder {
  width: 200px;
  padding-bottom: 15%;
}
#view .part--moodLogo.cb-file-empty {
  display: none;
}
.layout3 .part--moodLogo,
.layout5 .part--moodLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.layout3 .part--moodLogo .cb-image-figure,
.layout5 .part--moodLogo .cb-image-figure {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.layout3 .part--moodLogo .cb-image-container,
.layout5 .part--moodLogo .cb-image-container {
  width: auto;
}
@media (max-width: 1279px) {
  .part--moodLogo {
    margin-top: calc(var(--spacePart) * 3);
  }
  .layout3 .part--moodLogo,
  .layout5 .part--moodLogo {
    margin-top: var(--spacePart);
  }
}
.cbdModule--moodText {
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.part--moodImage {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  aspect-ratio: 1.38372093;
}
.part--moodImage * {
  height: 100%;
}
.part--moodImage img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.part--moodImage .placeholder {
  padding-bottom: 0;
}
.part--moodImage video {
  object-fit: cover;
  object-position: center;
}
@media (max-width: 1279px) {
  .part--moodImage {
    width: calc(100% + var(--deskSpace));
    height: var(--mobileImageHeight);
  }
}
.contact {
  float: left;
  width: 100%;
  margin: 40px 0;
}
.contact__content {
  float: left;
  width: calc(100% + 24px);
  margin-left: -12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 767px) {
  .contact__content {
    flex-direction: column;
  }
}
.contact__unit {
  float: left;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 767px) {
  .contact__unit {
    width: calc(100% - 24px);
  }
}
.contact__unit--2 {
  align-self: flex-end;
}
@media (max-width: 767px) {
  .contact__unit--2 {
    align-self: flex-start;
    margin: 40px 0;
    width: auto;
  }
}
.contact__title {
  float: left;
  font-size: 54px;
  line-height: 1.18518519;
  font-weight: 900;
  margin-bottom: 24px;
}
.newsletterLink {
  float: left;
  font-size: 54px;
  line-height: 1.18518519;
  font-weight: 900;
  text-decoration: none;
  color: #000 !important;
}
.vcard {
  float: left;
}
.vcard a {
  text-decoration: none;
}
.sqs {
  float: left;
  width: 100px;
}
.footcontent {
  float: left;
  width: 100%;
  margin: 40px 0;
}
.footarea {
  float: left;
  width: calc(100% + 24px);
  margin-left: -12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
@media (max-width: 1279px) {
  .footarea {
    flex-wrap: wrap;
  }
}
.footunit {
  float: left;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 1279px) {
  .footunit--1 {
    width: calc(100% - 24px);
    margin-bottom: 40px;
  }
}
#legal {
  float: left;
}
#legal .meta {
  float: left;
  text-decoration: none;
  margin-right: 20px;
  font-size: 14px;
  line-height: 1.57142857;
}
#legal .meta:last-child {
  margin-right: 0;
}
#social {
  float: left;
  display: flex;
}
#social .meta {
  float: left;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 76px;
  height: 76px;
  margin-right: 40px;
  background-color: #000;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
}
#social .meta:hover,
#social .meta:focus {
  background-color: #004093;
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta.service_linkedin {
  mask-image: url(/images/icon-linkedin.svg);
}
.partner {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.partner__title {
  float: left;
  font-weight: 600;
}
.unit--partnerUnit {
  float: left;
  margin-top: 20px;
}
.unit--partnerUnit .unit__body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -20px;
  margin-bottom: -20px;
}
.unit--partnerUnit .part {
  width: auto;
  min-width: 50px;
  margin-right: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.unit--partnerUnit .part:last-child {
  margin-right: 0;
}
#edit .unit--partnerUnit {
  min-height: 60px;
  min-width: 300px;
  outline-color: #ccc;
}
@media (max-width: 767px) {
  .unit--partnerUnit .unit__body {
    flex-wrap: wrap;
  }
}
.socialWall {
  float: left;
  width: 100%;
  margin-top: var(--spaceTotal);
  display: none;
}
.layout5 .socialWall {
  display: block;
}
.socialWall__title {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-right: 460px;
  min-height: 364px;
  display: flex;
  align-items: center;
  background-size: auto 364px;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/topics-large.svg);
}
@media (max-width: 1279px) {
  .socialWall__title {
    min-height: 200px;
    background-size: auto 200px;
    padding-right: 260px;
  }
}
@media (max-width: 767px) {
  .socialWall__title {
    padding-right: 140px;
    min-height: 100px;
    background-size: auto 100px;
  }
}
.socialWall__content {
  float: left;
  width: 100%;
  position: relative;
  z-index: 2;
  margin-top: calc((var(--spaceUnit) - var(--spacePart)) * -1);
}
a {
  color: inherit;
  text-decoration: underline;
}
a:hover,
a:focus {
  color: #004093;
}
h1,
h2 {
  font-size: 54px;
  line-height: 1.18518519;
  font-weight: 900;
}
h3 {
  font-size: 36px;
  line-height: 1.16666667;
  font-weight: 900;
}
h4 {
  font-size: 28px;
  line-height: 1.28571429;
  font-weight: 900;
  color: #004093;
}
h5 {
  font-size: 28px;
  line-height: 1.28571429;
  font-weight: 900;
}
.loud {
  font-size: 28px;
  line-height: 1.28571429;
}
.area--one {
  display: flex;
  flex-wrap: wrap;
}
.area--one .unitOne--intended {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.area--one .unitOne--intended .unit__content {
  max-width: 1018px;
}
.area--one .unitTwo {
  box-sizing: border-box;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  border: var(--generalBorderWidth) solid #DEE4ED;
  padding: calc(40px - var(--spacePart)) 20px;
}
@media (max-width: 767px) {
  .area--one .unitTwo {
    padding: calc(20px - var(--spacePart)) 10px;
  }
}
.area--one .unitThree {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  padding-top: calc((var(--spaceTotal) * 2) + var(--generalBorderWidth));
  padding-bottom: calc((var(--spaceTotal) * 2) + var(--generalBorderWidth));
  position: relative;
}
.area--one .unitThree + .unitThree,
.area--one .unitThree + .unitFour,
.area--one .unitThree + .unit--form {
  margin-top: calc((var(--spaceTotal) + var(--generalBorderWidth)) * -1);
}
.area--one .unitThree:before,
.area--one .unitThree:after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: var(--generalBorderWidth);
  background-color: #DEE4ED;
}
.area--one .unitThree:before {
  top: 0;
}
.area--one .unitThree:after {
  bottom: 0;
}
.area--one .unitThree .unit__background {
  order: 2;
  margin-right: calc(var(--contentOverflow) * -1);
}
.area--one .unitThree .unit__content {
  order: 1;
  margin-top: calc(var(--spacePart) * -1);
  margin-bottom: calc(var(--spacePart) * -1);
}
@media (max-width: 1279px) {
  .area--one .unitThree {
    flex-direction: column;
  }
  .area--one .unitThree .unit__background {
    width: calc(100% + var(--deskSpace)) !important;
    margin-bottom: var(--spacePart);
    order: 1;
    height: var(--mobileImageHeight);
  }
  .area--one .unitThree .unit__background * {
    height: 100%;
  }
  .area--one .unitThree .unit__background img {
    width: 100%;
    height: 100%;
    max-width: 100% !important;
    max-height: 100% !important;
    object-fit: cover;
    object-position: center;
  }
  .area--one .unitThree .unit__background .placeholder {
    padding-bottom: 0;
  }
  .area--one .unitThree .unit__content {
    order: 2;
    margin-top: 0;
    width: 100% !important;
  }
}
.area--one .unitThree:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 100vw;
  height: 100%;
  transform: translateX(-50%);
}
.area--one .unitThree:after {
  display: none;
}
.area--one .unitThree .unit__background {
  position: relative;
  z-index: 2;
}
.area--one .unitThree .unit__content {
  position: relative;
  z-index: 2;
}
.area--one .unitThree--imageWidthWide .unit__background {
  width: calc((100% - (400px + 24px)) + var(--contentOverflow));
}
.area--one .unitThree--imageWidthWide .unit__content {
  width: 400px;
}
.area--one .unitThree--imageWidthSlim .unit__background {
  width: calc((100% - (696px + 24px)) + var(--contentOverflow));
}
.area--one .unitThree--imageWidthSlim .unit__content {
  width: 696px;
}
.area--one .unitThree--borderTop:before {
  border-top: var(--generalBorderWidth) solid #DEE4ED;
}
.area--one .unitThree--borderBottom:after {
  display: block;
}
.area--one .unitThree--borderBoth:before {
  border-top: var(--generalBorderWidth) solid #DEE4ED;
}
.area--one .unitThree--borderBoth:after {
  display: block;
}
.area--one .unitFour {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  position: relative;
  padding-top: calc((var(--spaceTotal) * 2) + var(--generalBorderWidth));
  padding-bottom: calc((var(--spaceTotal) * 2) + var(--generalBorderWidth));
}
.area--one .unitFour + .unitThree,
.area--one .unitFour + .unitFour,
.area--one .unitFour + .unit--form {
  margin-top: calc((var(--spaceTotal) + var(--generalBorderWidth)) * -1);
}
.area--one .unitFour:before,
.area--one .unitFour:after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: var(--generalBorderWidth);
  background-color: #DEE4ED;
}
.area--one .unitFour:before {
  top: 0;
}
.area--one .unitFour:after {
  bottom: 0;
}
.area--one .unitFour .unit__background {
  order: 2;
  margin-right: calc(var(--contentOverflow) * -1);
}
.area--one .unitFour .unit__content {
  order: 1;
  margin-top: calc(var(--spacePart) * -1);
  margin-bottom: calc(var(--spacePart) * -1);
}
@media (max-width: 1279px) {
  .area--one .unitFour {
    flex-direction: column;
  }
  .area--one .unitFour .unit__background {
    width: calc(100% + var(--deskSpace)) !important;
    margin-bottom: var(--spacePart);
    order: 1;
    height: var(--mobileImageHeight);
  }
  .area--one .unitFour .unit__background * {
    height: 100%;
  }
  .area--one .unitFour .unit__background img {
    width: 100%;
    height: 100%;
    max-width: 100% !important;
    max-height: 100% !important;
    object-fit: cover;
    object-position: center;
  }
  .area--one .unitFour .unit__background .placeholder {
    padding-bottom: 0;
  }
  .area--one .unitFour .unit__content {
    order: 2;
    margin-top: 0;
    width: 100% !important;
  }
}
.area--one .unitFour .unit__background {
  width: calc((100% - (640px + 100px)) + var(--contentOverflow));
  position: relative;
}
.area--one .unitFour .unit__background:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100%;
  mix-blend-mode: multiply;
}
.area--one .unitFour .unit__content {
  width: 640px;
}
.area--one .unitFour--professionMpa .unit__background:after {
  background-color: #F86955;
}
.area--one .unitFour--professionDa .unit__background:after {
  background-color: #85D0EC;
}
.area--one .unitFour--professionTpa .unit__background:after {
  background-color: #94A791;
}
.area--one .unit--form {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  padding-top: calc((var(--spaceTotal) * 2) + var(--generalBorderWidth));
  padding-bottom: calc((var(--spaceTotal) * 2) + var(--generalBorderWidth));
  position: relative;
}
.area--one .unit--form + .unitThree,
.area--one .unit--form + .unitFour,
.area--one .unit--form + .unit--form {
  margin-top: calc((var(--spaceTotal) + var(--generalBorderWidth)) * -1);
}
.area--one .unit--form:before,
.area--one .unit--form:after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: var(--generalBorderWidth);
  background-color: #DEE4ED;
}
.area--one .unit--form:before {
  top: 0;
}
.area--one .unit--form:after {
  bottom: 0;
}
.area--one .unit--form .unit__background {
  order: 2;
  margin-right: calc(var(--contentOverflow) * -1);
}
.area--one .unit--form .unit__content {
  order: 1;
  margin-top: calc(var(--spacePart) * -1);
  margin-bottom: calc(var(--spacePart) * -1);
}
@media (max-width: 1279px) {
  .area--one .unit--form {
    flex-direction: column;
  }
  .area--one .unit--form .unit__background {
    width: calc(100% + var(--deskSpace)) !important;
    margin-bottom: var(--spacePart);
    order: 1;
    height: var(--mobileImageHeight);
  }
  .area--one .unit--form .unit__background * {
    height: 100%;
  }
  .area--one .unit--form .unit__background img {
    width: 100%;
    height: 100%;
    max-width: 100% !important;
    max-height: 100% !important;
    object-fit: cover;
    object-position: center;
  }
  .area--one .unit--form .unit__background .placeholder {
    padding-bottom: 0;
  }
  .area--one .unit--form .unit__content {
    order: 2;
    margin-top: 0;
    width: 100% !important;
  }
}
.area--one .unit--form:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 100vw;
  height: 100%;
  transform: translateX(-50%);
}
.area--one .unit--form .unit__background {
  position: relative;
  z-index: 2;
  width: calc((100% - (696px + 24px)) + var(--contentOverflow));
}
.area--one .unit--form .unit__content {
  width: 696px;
  position: relative;
  z-index: 2;
}
.area--one .unit--form.unit--backgroundTransparent {
  padding-top: 0;
}
.area .unit--fold {
  transition: all 0.4s;
}
.area .unit--fold div.more {
  display: block !important;
}
#view .area .unit--fold div.more {
  height: 0;
  overflow: hidden;
  transition: all 0.6s;
}
#view .area .unit--fold div.more .part {
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.2s;
}
#view .area .unit--fold.unit--foldOpen div.more {
  height: var(--js-elementHeight);
}
#view .area .unit--fold.unit--foldOpen div.more .part {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s 0.2s;
}
.area .unit--foldOne {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  border: var(--generalBorderWidth) solid #DEE4ED;
  box-sizing: border-box;
}
.area .unit--foldOne + .unit--foldOne {
  margin-top: calc((var(--spaceTotal) - 20px) * -1);
}
.area .unit--foldOne div.less {
  box-sizing: border-box;
  padding: 0 20px;
}
.area .unit--foldOne div.less .part:first-child {
  margin-top: 0;
}
.area .unit--foldOne div.less .part:first-child:last-child {
  margin-bottom: 0;
}
.area .unit--foldOne div.more {
  box-sizing: border-box;
  padding: 0 20px;
  max-width: 1060px;
}
.area .unit--foldOne div.more .part:last-child {
  margin-bottom: 20px;
}
.area .unit--foldOne .fold-toggle {
  width: calc(100% + 40px);
  margin-left: -20px;
  box-sizing: border-box;
  display: block;
  text-decoration: none;
  color: #000;
  padding: 24px 60px 24px 20px;
  position: relative;
}
.area .unit--foldOne .fold-toggle:after {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
  border-style: solid;
  border-color: transparent;
  border-top-color: #000;
  border-width: 16px 10px 0 10px;
  transition: all 0.24s;
}
.area .unit--foldOne .fold-toggle.fold-toggle--open:after {
  transform: rotate(-180deg);
}
.area .unit--foldOne .unit__foot {
  display: none;
}
#view .area .unit--foldOne.unit--foldOpen {
  background-color: var(--altBackground);
}
#edit .area .unit--foldOne {
  background-color: var(--altBackground);
}
.area--two .unit--foldOne div.more {
  max-width: 688px;
}
.area .unit--foldTwo div.more {
  max-width: 1020px;
}
.area .unit--foldTwo .unit__foot {
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.area .unit--foldTwo .unit__foot .ctrl a {
  float: left;
  text-decoration: none;
  font-weight: 600;
  padding-right: 40px;
  position: relative;
}
.area .unit--foldTwo .unit__foot .ctrl a:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  border-style: solid;
  border-color: transparent;
  border-top-color: #000;
  border-width: 18px 10px 0 10px;
}
.area .unit--foldTwo .unit__foot .ctrl a.less:after {
  transform: translateY(-50%) rotate(-180deg);
}
.area--twoB .unit + .unit {
  margin-top: calc(((var(--spaceTotal) + var(--spacePart)) - 60px) * -1);
}
.area--three .unit {
  border-top: var(--generalBorderWidth) solid #fff;
  border-bottom: var(--generalBorderWidth) solid #fff;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  padding-top: calc(50px - var(--spacePart));
  padding-bottom: calc(50px - var(--spacePart));
}
.area--three .unit + .unit {
  margin-top: calc((var(--spaceTotal) + var(--generalBorderWidth)) * -1);
}
@media (max-width: 767px) {
  .area--threeB {
    margin-top: calc(((var(--spaceTotal) * 2) + var(--generalBorderWidth)) * -1);
  }
}
.area--four {
  display: flex;
  flex-wrap: wrap;
}
.area--four .unit .part.pict .cb-image-container {
  position: relative;
  overflow: hidden;
}
.area--four .unit .part.pict .cb-image-container:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  transition: all 0.4s;
}
.area--four .unit .part.pict .cb-image-container img {
  transition: all 0.4s;
}
.area--four .unit.unit--isAnchor {
  color: inherit;
}
.area--four .unit.unit--isAnchor h2 {
  transition: all 0.4s;
}
.area--four .unit.unit--isAnchor:hover .part.pict .cb-image-container:after {
  width: 100px;
}
.area--four .unitOne--colorRed .part.pict .cb-image-container:after {
  background-color: #F86955;
}
.area--four .unitOne--colorRed.unit--isAnchor:hover h2 {
  color: #F86955;
}
.area--four .unitOne--colorLightblue .part.pict .cb-image-container:after {
  background-color: #85D0EC;
}
.area--four .unitOne--colorLightblue.unit--isAnchor:hover h2 {
  color: #85D0EC;
}
.area--four .unitOne--colorGreen .part.pict .cb-image-container:after {
  background-color: #94A791;
}
.area--four .unitOne--colorGreen.unit--isAnchor:hover h2 {
  color: #94A791;
}
.area--five {
  display: flex;
  flex-wrap: wrap;
}
.area--five .unitOne--1-3 {
  border-top: var(--generalBorderWidth) solid #fff;
  border-bottom: var(--generalBorderWidth) solid #fff;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  padding-top: calc(50px - var(--spacePart));
  padding-bottom: calc(50px - var(--spacePart));
}
.area--five .unitOne--1-3 + .unitOne--1-3 + .unitOne--1-3 + .unitOne--1-3 {
  margin-top: calc((var(--spaceTotal) + var(--generalBorderWidth)) * -1);
}
@media (max-width: 1279px) {
  .area--five .unitOne--1-3 + .unitOne--1-3 + .unitOne--1-3 {
    margin-top: calc((var(--spaceTotal) + var(--generalBorderWidth)) * -1);
  }
}
@media (max-width: 767px) {
  .area--five .unitOne--1-3 + .unitOne--1-3 {
    margin-top: calc((var(--spaceTotal) + var(--generalBorderWidth)) * -1);
  }
}
.area .unit--verticalSpaceBottomSmall {
  margin-bottom: calc((var(--spaceTotal) - var(--spacePart)) * -1);
}
.area .unit--backgroundTransparent:before {
  background-color: transparent;
}
.area .unit--backgroundWhite:before {
  background-color: #fff;
}
.area .unit--backgroundBlue:before {
  background-color: #DEE4ED;
}
.area .unit--backgroundLightblue:before {
  background-color: #F0F1F5;
}
.part.load .load {
  display: inline-block;
  text-decoration: none;
  padding-right: 30px;
  color: #000;
  position: relative;
}
.part.load .load:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 24px;
  mask-size: 16px 20px;
  mask-position: 100% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-load.svg);
  background-color: #004093;
}
.part.load .load:hover,
.part.load .load:focus {
  color: #004093;
}
.part--link .open,
.part--widget.link .open {
  display: inline-block;
  background-color: transparent;
  font-family: inherit;
  font-size: 18px;
  line-height: 1.33333333;
  font-weight: 600;
  cursor: pointer;
  color: #000;
  padding: 0 0 2px 0;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  background-size: 100% var(--generalBorderWidth);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #004093, #004093);
  transition: all 0.2s;
}
.part--link .open:hover,
.part--widget.link .open:hover,
.part--link .open:focus,
.part--widget.link .open:focus,
.unit--isAnchor:hover .part--link .open,
.unit--isAnchor:hover .part--widget.link .open {
  background-size: 0 var(--generalBorderWidth);
}
.togglenavigation {
  float: left;
  position: relative;
  width: 58px;
  height: 32px;
  cursor: pointer;
}
.togglenavigation:after {
  content: 'Menu';
  position: absolute;
  right: 100%;
  bottom: -5px;
  font-size: 18px;
  line-height: 1.22222222;
  font-weight: 600;
  color: #000;
  margin-right: 20px;
}
@media (max-width: 1279px) {
  .togglenavigation {
    width: 50px;
    height: 28px;
  }
}
@media (max-width: 767px) {
  .togglenavigation:after {
    display: none;
  }
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #000;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.navigation {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1999;
  width: 100%;
  background-color: #DEE4ED;
  box-sizing: border-box;
  padding-top: var(--headerHeight);
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  transform: translateY(-100%);
  transition: all 0.6s;
}
.navigation::-webkit-scrollbar {
  display: none;
}
body.cb-toggle-target-active .navigation {
  transform: translateY(0);
}
@media (max-width: 767px) {
  .navigation {
    height: 100%;
  }
}
.navigation__wrapper {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 767px) {
  .navigation__wrapper {
    min-height: 100%;
  }
}
.navigation__desk {
  float: left;
  width: var(--deskWidth);
  max-width: var(--deskMaxWidth);
}
.navigation__content {
  float: left;
  width: 100%;
  margin: 60px 0;
}
@media (max-width: 767px) {
  .navigation__content {
    margin-top: 40px;
  }
}
.navigation__section {
  float: left;
  width: 100%;
}
.navigation__section--1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 767px) {
  .navigation__section--1 {
    display: none;
  }
}
.navigation__section--2 {
  margin-top: 60px;
}
@media (max-width: 767px) {
  .navigation__section--2 {
    display: none;
  }
}
.navigation__section--3 {
  display: none;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 767px) {
  .navigation__section--3 {
    display: flex;
  }
}
.navigation__topics {
  float: left;
  width: auto;
  height: 46px;
}
.mobileVcard {
  float: left;
  width: 100%;
  margin-top: 24px;
  font-size: 18px;
  line-height: 1.33333333;
  font-weight: 600;
}
.mobileSQS {
  float: left;
  width: 100px;
  mix-blend-mode: multiply;
  margin-top: 24px;
}
.navigation__section--2 div.navi {
  float: left;
}
.navigation__section--2 div.navi > .item {
  float: left;
}
.navigation__section--2 div.navi > .item > .menu {
  display: block;
  position: relative;
  color: #000;
}
.navigation__section--2 div.navi > .item > .menu:hover,
.navigation__section--2 div.navi > .item > .menu:focus,
.navigation__section--2 div.navi > .item > .menu.path {
  color: #004093;
}
.navigation__section--2 div.sub1 {
  width: calc(100% + 24px);
  margin: -32px -12px;
  display: flex;
  flex-wrap: wrap;
}
.navigation__section--2 div.sub1 > .item {
  width: calc(20% - 24px);
  margin: 32px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.navigation__section--2 div.sub1 > .item > .menu {
  float: left;
  text-decoration: none;
  font-size: 28px;
  line-height: 1.28571429;
  font-weight: 900;
}
@media (max-width: 1279px) {
  .navigation__section--2 div.sub1 > .item {
    width: calc(50% - 24px);
  }
}
.navigation__section--2 div.sub2 {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.navigation__section--2 div.sub2 > .item {
  margin-top: 4px;
}
.navigation__section--2 div.sub2 > .item.init {
  margin-top: 0;
}
.navigation__section--2 div.sub2 > .item > .menu {
  text-decoration: none;
}
.navigation__section--3 div.navi {
  float: left;
}
.navigation__section--3 div.navi .cb-toggle {
  display: none;
}
.navigation__section--3 div.navi > .item {
  float: left;
}
.navigation__section--3 div.navi > .item > .menu {
  display: block;
}
.navigation__section--3 div.sub1 {
  width: 100vw;
  margin-top: 40px;
  margin-left: calc(var(--deskSpace) * -1);
}
.navigation__section--3 div.sub1 > .item {
  width: 100%;
  background-color: #fff;
  margin-top: 5px;
  position: relative;
  transition: all 0.4s;
}
.navigation__section--3 div.sub1 > .item.init {
  margin-top: 0;
}
.navigation__section--3 div.sub1 > .item:not(.item-empty) > .cb-toggle {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  height: 60px;
  width: 68px;
  cursor: pointer;
  transition: all 0.4s;
}
.navigation__section--3 div.sub1 > .item:not(.item-empty) > .cb-toggle:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-color: transparent;
  border-top-color: #000;
  border-width: 16px 10px 0 10px;
}
.navigation__section--3 div.sub1 > .item:not(.item-empty) > .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
}
.navigation__section--3 div.sub1 > .item:not(.item-empty).cb-toggle-target-active {
  background-color: transparent;
}
.navigation__section--3 div.sub1 > .item > .menu {
  padding: 13px calc((var(--deskSpace) * 2) + 20px) 11px var(--deskSpace);
  text-decoration: none;
  font-size: 28px;
  line-height: 1.28571429;
  font-weight: 900;
  color: #004093;
}
.navigation__section--3 div.sub2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 var(--deskSpace);
  height: 0;
  overflow: hidden;
  transition: all 0.6s;
}
.navigation__section--3 div.sub2 > .item {
  transform: translateY(-10px);
  opacity: 0;
  transition: all 0.2s;
  margin-top: 4px;
}
.navigation__section--3 div.sub2 > .item.exit {
  margin-bottom: 17px;
}
.navigation__section--3 div.sub2 > .item > .menu {
  font-size: 18px;
  line-height: 1.44444444;
  text-decoration: none;
  color: #000;
}
.navigation__section--3 div.sub2 > .item > .menu.path {
  font-weight: 900;
  color: #004093;
}
.navigation__section--3 div.sub1 > .item.cb-toggle-target-active div.sub2 {
  height: var(--js-elementHeight);
}
.navigation__section--3 div.sub1 > .item.cb-toggle-target-active div.sub2 > .item {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s 0.2s;
}
@media (max-width: 1279px) {
  .wrapper {
    font-size: 16px;
    line-height: 1.5;
  }
  h1,
  h2,
  .contact__title {
    font-size: 40px;
    line-height: 1.15;
  }
  h3 {
    font-size: 30px;
    line-height: 1.2;
  }
  h4,
  h5,
  .loud {
    font-size: 22px;
    line-height: 1.36363636;
  }
}
@media (max-width: 767px) {
  h1,
  h2,
  .contact__title {
    font-size: 32px;
    line-height: 1.1875;
  }
  h3 {
    font-size: 20px;
    line-height: 1.3;
  }
  h4,
  h5,
  .loud {
    font-size: 20px;
    line-height: 1.3;
  }
}
.ns-unitFilter {
  float: left;
  width: 100%;
}
.ns-unitFilter__wrapper {
  float: left;
  width: 100%;
}
.ns-unitFilter__toggle {
  display: none;
}
.ns-unitFilter__content {
  float: left;
  width: 50%;
}
@media (max-width: 1279px) {
  .ns-unitFilter__content {
    width: calc((100% / 3) * 2);
  }
}
@media (max-width: 767px) {
  .ns-unitFilter__content {
    width: 100%;
  }
}
.ns-unitFilter__list {
  float: left;
  width: calc(100% + 24px);
  margin: -8px -12px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
@media (max-width: 767px) {
  .ns-unitFilter__list {
    width: calc(100% + 20px);
    margin: -5px -10px;
  }
}
.ns-unitFilter__item {
  float: left;
  margin: 8px 12px;
  width: calc(50% - 24px);
  cursor: pointer;
  text-decoration: none;
  padding-left: 38px;
  box-sizing: border-box;
  position: relative;
  font-weight: 600;
  color: #000 !important;
}
.ns-unitFilter__item:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  box-sizing: border-box;
  border: var(--generalBorderWidth) solid #DEE4ED;
}
.ns-unitFilter__item.is-active:before {
  background-color: #DEE4ED;
}
@media (max-width: 767px) {
  .ns-unitFilter__item {
    padding-left: 30px;
    margin: 5px 10px;
    width: calc(50% - 20px);
  }
}
.ns-unitFilter__unit {
  display: none;
  transition: all 0.4s;
  opacity: 0;
  transform: scale(0.8);
}
.ns-unitFilter__unit.ns-unitFilter__unit--activated {
  display: block;
}
.ns-unitFilter__unit.ns-unitFilter__unit--active {
  opacity: 1;
  transform: scale(1);
}
.quicklinks {
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -62px;
  z-index: 10;
}
.quicklinks__toggle {
  position: absolute;
  right: 0;
  bottom: 100%;
  z-index: 3;
  transform: rotate(-90deg);
  transform-origin: 100% 100%;
  background-color: #004093;
  color: #fff !important;
  cursor: pointer;
  font-weight: 900;
  text-decoration: none;
  padding: 7px 20px;
  border-radius: 5px;
}
.quicklinks__content {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  width: 188px;
  padding-right: 38px;
  box-sizing: border-box;
  transform: translateX(100%);
  transition: all 0.4s;
}
.quicklinks__content:after {
  content: '';
  position: absolute;
  right: 100%;
  margin-right: 38px;
  top: 30px;
  border-style: solid;
  border-color: transparent;
  border-right-color: #004093;
  border-width: 12px 16px 12px 0;
  transition: all 0.4s;
}
.quicklinks.cb-toggle-target-active .quicklinks__content {
  transform: translateX(0);
}
.quicklinks.cb-toggle-target-active .quicklinks__content:after {
  margin-right: 0;
}
.unit--quicklinksUnit {
  float: left;
  width: 100%;
  background-color: #DEE4ED;
  border-radius: 5px;
  text-align: right;
  box-sizing: border-box;
  padding: 13px 16px;
}
#edit .unit--quicklinksUnit {
  min-height: 40px;
}
.unit--quicklinksUnit .part {
  margin-top: 3px;
  margin-bottom: 3px;
}
.unit--quicklinksUnit .part--link .open {
  padding-bottom: 0;
  background: none !important;
  transition: none;
  font-weight: 400;
}
.unit--quicklinksUnit .part--link .open:hover,
.unit--quicklinksUnit .part--link .open:focus {
  color: #004093;
}
/*# sourceMappingURL=./screen-small.css.map */